import { Inject, Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { platformSettings, userSettings } from 'src/app/shared/constants/url.constants';
import { environment } from 'src/environments/environment';
import { SettingsService } from './settings.service';
import { FooterService } from './footer.service';
import { StorageService } from './storage.service';
import { UikitService } from './uikit.service';
import { BaseService } from './base.service';
import { ConfigurationService } from './configuration.service';
import { LoggerService } from '../authentication/logger.service';
import { LoadService } from './load.service';
import { LanguageService } from './language.service';
import { Router } from '@angular/router';
import { VersionService } from './version.service';
import * as $ from 'jquery';
import { DOCUMENT } from '@angular/common';
import { PlanService } from './plan.service';
import { EventService } from './event.service';
import { CookieService } from 'ngx-cookie';
import { PaymentService } from './payment.service';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { BehaviorSubject } from 'rxjs';
import { SubscriptionService } from './subscription.service';

declare global {
  interface Window {
    dataLayer: Array<any>;
    dengage:any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  public closeDeviceEdit = new BehaviorSubject(false);
  paramurl;
  params;
  type;
  region;
  drmConfig;
  displayQualityLevel;
  phoneCodes: any;
  hooplaParams:any = {};
  regionsList;
  userPaymentMethods: any;
  telcoContries;
  userLoginBy;
  userIntegrationType;

  constructor(private http: HttpClient, private settingsService: SettingsService,
              private footerService: FooterService, private storageService: StorageService,
              private uikitService: UikitService, private baseService: BaseService,
              private configService: ConfigurationService, private loggerService: LoggerService,
              private loadService: LoadService, private languageService: LanguageService,
              private router: Router, private versionService: VersionService,
              private planService: PlanService,private eventService: EventService,
              @Inject(DOCUMENT) private HTMLdocument: HTMLDocument,
              private cookieService: CookieService, private paymentService : PaymentService,
              private subscriptionService: SubscriptionService
  ) {
    window.dataLayer = window.dataLayer || [];
  }
// this function calling for get the user deatils local store .
  getLocalData = () => {
    const usercookieId = this.cookieService.get('u_id');
    if (usercookieId) {
      localStorage.setItem('u_id', usercookieId);
    }
    const guestcookieId = this.cookieService.get('g_id');
    if (guestcookieId) {
      localStorage.setItem('g_id', guestcookieId);
    }
    const devicecookieId = this.cookieService.get('d_id');
    if (devicecookieId) {
      localStorage.setItem('d_id', devicecookieId);
    }
    return {
      userId : this.storageService.getLocalStore('u_id'),
      deviceId : this.storageService.getLocalStore('d_id'),
      guestId : this.storageService.getLocalStore('g_id'),
      localeSetting : this.storageService.getLocalStore('locale'),
    }
  }

  // this method for User setting details.
  getParams = (logged_in, localeSetting, debugRegion, debugCountryCode) => {
    let params = new HttpParams()
        .set('d_type', 'web')
        .set('logged_in', logged_in)
        .set('locale', localeSetting);
    if (debugRegion && debugRegion !== '') {
      params = params.append('debug_region', debugRegion);
    }
    if (debugCountryCode && debugCountryCode !== ''){
      params = params.append('debug_country_code', debugCountryCode);
    }
    return params;
  }

  addMatomo = (uId, dId) => {
    if(uId){
      const bodyScript = "<script>var _paq = window._paq = window._paq || []; window._paq.push(['appendToTrackingUrl', 'new_visit=1']); _paq.push(['setUserId', '" + uId + "']);_paq.push(['setCustomVariable',1,'deviceId', '" + dId + "','visit']); window._paq.push(['trackPageView']); window._paq.push(['appendToTrackingUrl', '']);</script>";
      $('body').append(bodyScript);
    }
  }

  setColours = (settingsData) => {
    document.body.style.setProperty('--bg-color', settingsData.background_color);
    document.body.style.setProperty('--mm-color', settingsData.menu_color);
    document.body.style.setProperty('--card-color', settingsData.card_color);
    document.body.style.setProperty('--button-color', settingsData.button_color);
    document.body.style.setProperty('--footer-color', settingsData.footer_color);
    document.body.style.setProperty('--bg-font-color', settingsData.background_font_color);
    document.body.style.setProperty('--mm-font-color', settingsData.menu_font_color);
    document.body.style.setProperty('--card-font-color', settingsData.card_font_color);
  }

  addCaptcha = (settingsData) => {
    const enterPriceCaptcha = this.HTMLdocument.createElement('script');
    enterPriceCaptcha.src = "https://www.google.com/recaptcha/enterprise.js?render="+settingsData.google_recaptcha_key;
    $('head').append(enterPriceCaptcha);
  }

  addScripts = (settingsData) => {
    $('head').append(settingsData.google_analytics);
    $('head').append(settingsData.header_scripts);
    $('body').prepend(settingsData.body_scripts);
  }

  addFavicon = (settingsData) => {
    const favIcon = this.HTMLdocument.createElement('link');
    favIcon.rel = 'icon';
    favIcon.type = 'image/png';
    if (settingsData.image_base_path && settingsData.site_icon) {
      favIcon.href = settingsData.image_base_path + settingsData.site_icon;
    } else {
      favIcon.href = 'https://stagingstatic.tv2zcdn.com/fbstagingassets/images/6ac42ec455311e03794e16df07dc40b6bec9c38d.png';
    }
    $('head').append(favIcon);
  }

  handleError = (error) => {
    // error_codes 1011,1012
    this.baseService.loaderSource.next(false);
    if ((error.error.error.code === 1018 || error.error.error.code === 1019)) {
      this.loggerService.updatedMemoryStorage(undefined);
      this.storageService.removeLocalStore('a_t');
      this.storageService.removeLocalStore('r_t');
      this.storageService.setLocalStore('logged_in', '0');
      this.storageService.removeLocalStore('u_id');
      this.storageService.removeLocalStore('sessionStorage');
      this.storageService.removeLocalStore('event_user_status');
      this.storageService.removeLocalStore('g_id');
      this.storageService.removeLocalStore('d_id');
      document.cookie = 'u_id=; domain='+environment.cookieDomain+'; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      this.storageService.removeLocalStore('contentURL');
      window.location.reload();
      this.uikitService.notifyError(error.error);
    } else {
      this.uikitService.notifyError(error.error);
    }
  }

  // this  service call User setting details.
  getUserSettings:any = async(params, paramurl) => {
    let settingsUrl = environment.apiUrl + environment.v3_version + userSettings;
    if (paramurl) {
      settingsUrl = settingsUrl + paramurl;
    }
    return new Promise((resolve, reject) => {
      return this.http.post<any>(settingsUrl, {}, { params: params })
        .subscribe(
          async (response) => {
            if (response.status_code === 200) {
              const respData = response.data;
              const userPhoneCode = this.phoneCodes.find((obj) => obj.country_code.toLowerCase() === respData?.configuration?.country_code.toLowerCase());
              this.loggerService.isVoucherSuspended = respData.user?.voucher?.is_voucher_suspended;
              this.loggerService.userTelco = respData?.user?.user_telco;
              this.subscriptionService.userType = respData.user.user_type || 0;
              this.subscriptionService.userDetails = {name: respData.user?.user_name, email: respData.user?.user_email};
              // code to check stripe_payment_method is present and if present storing it in a variable
              if (respData?.user?.stripe_payment_method?.length > 0) {
                this.userPaymentMethods = respData?.user?.stripe_payment_method;
              }
              if (userPhoneCode) this.storageService.setLocalStore('phone_code', userPhoneCode.phone_code);
              else this.storageService.setLocalStore('phone_code', '');
              return resolve(respData);
            }
            else {
              this.uikitService.notifyError(response.error);
              return resolve(response.error);
            }
          },
          (error) => {
            this.handleError(error);
            return resolve(error);
          }
        );
    });
  }

  getUserPhoneCodeObj() {
    const userPhoneCode = this.storageService.getLocalStore('phone_code');
    const userPhoneCodeObj = this.phoneCodes.find((obj) => obj?.phone_code == userPhoneCode) || {};
    return userPhoneCodeObj;
  }

  async doAutologin() {
    const currentURL: string = window.location.href;
    if(currentURL.includes("kb.idm.oclc.org")) {
      var data = {
        "email":"ezproxyaccess@tv2z.com",
        "password":"up6QN8TBLkFc7qzY",
        "login_by":"manual",
        "device_token":"123456",
        "region": this.storageService.getLocalStore('region') || 'int',
        "d_type":"web",
        "locale":this.storageService.getLocalStore('locale') || 'en-US',
      };
      Object.assign(data, this.settingsService.handleDataMartAnalyticsParamsData());
      await this.loggerService.loginFnEzproxy(data);
    }
  }

  initApp = async () => {
    this.generateUniqueId();
    let {userId, deviceId, guestId, localeSetting} = this.getLocalData();
    const locationSearch = location.search;
    let debugRegion = '';
    let debugCountryCode = '';
    const checkType = /type=([a-z.]*)/;
    const checkRegion = /region=([a-z.]*)/;
    const checkDevice = /device_type=([a-z.]*)/;
    const checkCountryCode = /country_code=([a-z.]*)/;
    this.baseService.initLocationHref = location.href;
    if (locationSearch.match(checkType)) {
      if (locationSearch.match(checkType)[1] !== undefined && locationSearch.match(checkType)[1] === 'debug') {
        debugRegion = locationSearch.match(checkRegion) ? locationSearch.match(checkRegion)[1] : null;
        debugCountryCode = locationSearch.match(checkCountryCode) ? locationSearch.match(checkCountryCode)[1] : null;
        const deviceType = locationSearch.match(checkDevice) ? locationSearch.match(checkDevice)[1] : null;
        this.storageService.setLocalStore('debug_region', debugRegion);
        this.storageService.setLocalStore('debug_country_code', debugCountryCode);
        if (deviceType === 'iOS') {
          window.location.href = `filmbox://region=${debugRegion}&type=debug`;
        }
      }
    }
    debugRegion = this.storageService.getLocalStore('debug_region') ?? debugRegion;
    debugCountryCode = this.storageService.getLocalStore('debug_country_code') ?? debugCountryCode;
    localeSetting = localeSetting ?? this.configService.defaultLanguage;
    const locale = this.cookieService.get('locale');
    if (locale) {
      localeSetting = locale;
      this.storageService.setLocalStore('locale', locale);
    }
    let logged_in = 0;
    if (deviceId) {
      if (userId) {
        this.paramurl = '/' + deviceId + '/' + userId;
        logged_in = 1;
      } else if (guestId) {
        this.paramurl = '/' + deviceId + '/' + guestId;
      }
      else {
        this.storageService.removeAll();
        window.location.reload();
      }
    } else {
      this.paramurl = '';
    }
    
    if(this.storageService.getLocalStore('universal_user') == 'hoopla') {
      let hooplaEndTime = Date.parse(this.storageService.getLocalStore('hoopla_end_time'));
      let currentDateObj = new Date(); // today's date and time in ISO format
      let currentDateTime = Date.parse(currentDateObj.toISOString());
      const error = {"error": {"error" : {"message" : "TransactionId has been Expired"}}};
      if (currentDateTime > hooplaEndTime) {
        this.uikitService.notifyError(new HttpErrorResponse(error));
        this.loggerService.removeLogoutData();
        this.storageService.removeLocalStore('g_id');
        this.storageService.removeLocalStore('d_id');
        window.location.reload();
      }
    }
    this.params = this.getParams(logged_in, localeSetting, debugRegion, debugCountryCode);
    // this.baseService.loaderSource.next(true);
    let settingsUrl = environment.apiUrl + environment.v3_version + platformSettings;
    return new Promise((resolve, reject) => {
      return this.http.get<any>(settingsUrl, { params: this.params })
        .subscribe(
          async (response) => {
            var self = this;
            if (response.status_code === 200) {
              const respData = response.data;
              this.telcoContries = respData.telco_countries;
              this.drmConfig = respData.drm_config;
              this.phoneCodes  = respData.phone_codes ?? [];
              const settingsData = respData.settings;
              this.displayQualityLevel = settingsData.display_quality_levels_on_player;
              const user_settings = await this.getUserSettings(self.params, self.paramurl);
              let nCountryCode = user_settings.configuration['country_code'];
              let nRegion = user_settings.configuration.region['region_code'];
              let oldRegion = this.storageService.getLocalStore('region');
              if(nRegion != oldRegion){
                this.storageService.setLocalStore('country_code',nCountryCode);
                this.storageService.setLocalStore('region',nRegion);
                const pathNameArr = location.pathname.split('/');
                const regionPath : any = pathNameArr[1];
                if(regionPath != 'hoopla' && regionPath != 'snappet') location.pathname = location.pathname.replace(regionPath, nRegion);
              }
              const user_region = user_settings.configuration.region.region_code;
              const plan = respData.plan[user_region];
              const pathNameArr = location.pathname.split('/');
              const regionPath = pathNameArr[1];
              const regionAvailable = respData.configuration.regions;
              if(respData.settings.geo_blocking_enabled) {
                const countryCode = user_settings.configuration.country_code;
                this.storageService.setLocalStore('country_code', countryCode);
              } else {
                this.storageService.removeLocalStore('country_code')
                this.storageService.removeLocalStore('debug_country_code')
              }
              const region = this.storageService.getLocalStore('region');
              const notAvailable = settingsData.not_available_region;
              if(notAvailable != '' && region != '' && notAvailable.includes(region)){
                this.router.navigateByUrl('/not-available');
              }
              if (locationSearch.match(checkType) && locationSearch.match(checkType)[1] !== undefined && locationSearch.match(checkType)[1] === 'debug') {
                if (debugRegion && debugRegion !== regionPath && regionAvailable.includes(regionPath)) {
                  this.storageService.removeLocalStore('locale');
                  location.pathname = location.pathname.replace(regionPath, debugRegion);
                }
              }
              if (user_settings.user) {
                this.paymentService.paymentMethodLength = user_settings.user.stripe_payment_method.length;
                if (!this.storageService.getLocalStore('d_id') || !this.storageService.getLocalStore('g_id')) {
                  this.storageService.setLocalStore('d_id', user_settings.user.d_id);
                  this.storageService.setLocalStore('g_id', user_settings.user.g_id);
                }
              }
              this.eventService.eventFun();
              await this.loadService.getToken();
              this.planService.setPlanDetails(plan);
              this.baseService.checkDevice();
              this.loadService.getSettings();
              this.versionService.setVersion(respData.version);
              this.settingsService.settingFn(respData.settings);
              this.footerService.pageDetailsFn(respData.pages[user_region] || []);
              this.languageService.languageFn(respData.configuration.locales);
              this.baseService.allPages = respData.pages[user_region];
              let configData = respData.configuration;
              configData.region = user_settings.configuration.region;
              if (configData) {
                await this.configService.loacaleFn(configData);
              }
              if(respData.settings.hoopla_enabled) {
                try {
                  const pathLocationArr = location.pathname.split('/');
                  let urlPath;
                  let hooplatoken;
                  if(pathLocationArr.length == 3) {
                    urlPath = pathLocationArr[1];
                    hooplatoken = pathLocationArr[2];
                  } else {
                    urlPath = pathLocationArr[2];
                    hooplatoken = pathLocationArr[3];
                  }
    
                  if(urlPath == 'hoopla' || urlPath == 'snappet') {
                    this.hooplaParams.d_id = this.storageService.getLocalStore('d_id');
                    this.hooplaParams.g_id = this.storageService.getLocalStore('g_id');
                    this.hooplaParams.d_type = "web";
                    this.hooplaParams.region = this.storageService.getLocalStore('region') || 'int';
                    this.hooplaParams.locale = this.storageService.getLocalStore('locale') || 'en-US';
                    this.hooplaParams.universalKey = hooplatoken;
                    this.hooplaParams.loginCustomer = urlPath;
                    this.hooplaParams.login_by = "manual";
                    if (urlPath == 'snappet') {
                      const contentURLParms: RegExp = /[?]contentUrl=([^&#]+)/;
                      if (locationSearch.match(contentURLParms)) this.hooplaParams.contentUrl = locationSearch.match(contentURLParms) ? locationSearch.match(contentURLParms)[1] : null;
                    }
                    await this.loggerService.hooplaLogin(this.hooplaParams);
                  }
                  } catch (ex){
                    console.log(ex);
                  }
              }
              if(!respData?.user?.logged_in && environment.isEzProxy) this.doAutologin();
              await this.configService.setBGImage();
              this.baseService.loginMode = user_settings.login_mode;
              const isGuestSource = (userId) ? false : true;
              this.baseService.startState = (respData.login_mode === 0) ? '/home' : '/auth/signup';
              this.baseService.guestSource.next(isGuestSource);
              if (settingsData) {
                // Site theme color settings
                this.setColours(settingsData);
                const uId = this.storageService.getLocalStore('u_id');
                const dId = this.storageService.getLocalStore('d_id');
                this.addMatomo(uId, dId);
                this.addCaptcha(settingsData);
                this.addFavicon(settingsData);
                this.addScripts(settingsData);
                resolve(respData);
              }
            } else {
              this.uikitService.notifyError(response.error);
              resolve(response.error);
            }
          },
          (error) => {
            // error_codes 1011,1012
            this.baseService.loaderSource.next(false);
            this.handleError(error);
            resolve(error);
          }
        );
    });
  }

  generateUniqueId(){
    const fpPromise = FingerprintJS.load();
    (async () => {
      const fp = await fpPromise;
      const result = await fp.get();
      this.storageService.setLocalStore('uniq_id', result.visitorId);
    })()
  }
}