import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PlanService {
  planDetail: any;
  freeTrialText: any;
  freeTrialDays: any;
  planAmount: any;
  planText: any;
  plansLength: any
  constructor() { }

  setPlanDetails(plan){
    this.planDetail  = plan.plan_detail;
    if(plan.free_trial_days > 0) this.freeTrialText = plan.free_trial_text;
    this.freeTrialDays = plan.free_trial_days;
    this.planAmount = plan.amount;
    this.plansLength = plan['no_of_plans'];
    // to display plan info when single plan is present in home page detail page etc
    this.planText = plan.plan_text;
    localStorage.setItem('planInfo', this.planAmount);
  }
}
