export const environment = {
    production: true,
    environmentName: 'SIT',
    apiUrl: 'https://rcsitapi.tv2zdev.com/sit/',
    socialUrl: 'https://rcsitapi.tv2zdev.com/',
    version: 'v2/',
    v3_version:'v3/',
    routeUrl: 'https://rcsitssr.tv2zdev.com/',
    ezProxyUrl: 'https://kb.idm.oclc.org/login?url=https://bibliotheekpreprod.thereadingcorner.tv',
    isEzProxy: false,
    cookieDomain:'tv2zdev.com',
    paywallURL: '',
    appName: "readingcorner",
    loader: 'tv2z',
    appVersion: '1.0.0',
    htmlAppName: 'film1'
};
