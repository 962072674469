import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { continueWatching, homeAssets, removeContinue, userContent, bannerPersona } from 'src/app/shared/constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { UikitService } from './uikit.service';
import { environment } from 'src/environments/environment';
import { WishlistService } from './wishlist.service';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  public contentWishlist = new BehaviorSubject<Array<string>>([]);
  public contentWishlistData = new BehaviorSubject<Array<object>>([]);
  public userData = new BehaviorSubject<Array<object>>([]);
  updateUserContent = new BehaviorSubject<boolean>(true);
  updateUserContentFlag = true;
  updateCategoryContentFlag = true;
  public wishlistCount;
  removeListFlage = false;
  constructor(private http: HttpClient, private uikitService: UikitService, private wishlistService: WishlistService,
    private settingsService: SettingsService) { }
//this service calling for get home content list details
  assets(): Observable<any> {
    const homeAssetsURL = environment.apiUrl + environment.v3_version + homeAssets;
    const params: HttpParams = new HttpParams()
      .set('d_type', 'web');
    return this.http.get(homeAssetsURL, { params }).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          return response.data;
        }
        else {
          this.uikitService.notifyError(response);
        }
      }), catchError((error) => {
        if (error?.error?.error?.code !== 1002) {
          this.uikitService.notifyError(error);
        }
        return error;
      })
    );
  }

  continueWatching(data): Observable<any>{
    let params: HttpParams;
    const continueWatchURl = environment.apiUrl + environment.version + continueWatching;
    params = new HttpParams()
      .set('d_type', 'web')
      .set('skip', data.skip)
      .set('take',data.take)
    if(data?.category) {
      params = params.append('category', data?.category)
    }
    return this.http.get(continueWatchURl, { params }).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          return response.data;
        }
        else {
          this.uikitService.notifyError(response);
        }
      }), catchError((error) => {
        if (error?.error?.error?.code !== 1002) {
          this.uikitService.notifyError(error);
        }
        return error;
      })
    );
  }

  userContent(slug?:any): Observable<any>{
    try {
    const title = slug?.title;
    const skip = slug?.skip;
    const take = slug?.take;
    let params: HttpParams;
    const userContentAPI = environment.apiUrl + environment.version + userContent;
    params = new HttpParams()
        .set('d_type', 'web');
    if(title == 'wishlist'  && !this.removeListFlage){
      params = params.append('skip', skip)
          .append('take', take);
    }
    if(slug?.category) {
      params = params.append('category', slug?.category)
    }
    if(slug?.content_type){
      params = params.append('content_type', slug.content_type)
    }
    if(this.wishlistService.seekCount && this.removeListFlage){
      const seekVal = this.wishlistService.seekCount + this.settingsService.takeValue;
      params = params.append('skip', '0')
          .append('take', seekVal );
    }
    return this.http.get(userContentAPI, { params }).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          return response.data;
        }
        else {
          this.uikitService.notifyError(response);
        }
      }), catchError((error) => {
        if (error?.error?.error?.code !== 1002) {
          this.uikitService.notifyError(error);
        }
        return error;
      })
    );
    } catch (error) {
        console.log(error)
    }
  }

  removeContinueWatch(continueId: string): Observable<any>{
    const removeContinueAPI = environment.apiUrl + environment.version + removeContinue;
    const postData = { d_type : 'web', continue_id: continueId };
    return this.http.post(removeContinueAPI, postData ).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          return response.data;
        }
        else {
          this.uikitService.notifyError(response);
        }
      }), catchError((error) => {
        if (error?.error?.error?.code !== 1002) {
          this.uikitService.notifyError(error);
        }
        return error;
      })
    );
  }

  bannerpersonainfo(banner, region, locale): Observable<any>{
    banner = banner.map((slide)=>{
      return slide.content_id;
    })
    const bannerPersonaURl = environment.apiUrl + environment.version + bannerPersona;
    const data = {"banner_content_id" : banner, "d_type" : 'web', "region" : region, "locale" : locale};
    return this.http.post(bannerPersonaURl, data).pipe(
      map((response: any) => {
        if (response.status_code === 200) {
          return response.data;
        }
        else {
          this.uikitService.notifyError(response);
        }
      }), catchError((error) => {
        if (error.error && error.error.code !== 1002) {
          this.uikitService.notifyError(error);
        }
        return error;
      })
    );
  }

  addPersonaData(personaData,datas) {
    const continueData = personaData.find((persona: any) => {return persona.key === 'continue'});
    const wishlistData = personaData.find((persona: any) => {return persona.key === 'wishlist'});
    datas = datas.map((item) => {
      if (item.component === 'continue_watching_widget') {
         return {...item, ...continueData};
      } else if (item.component === 'my_list_widget') {
         return {...item, ...wishlistData};
      } else {
        return item;
      }
    });
    return datas
  }
}
